import axios from 'axios';

//Verifying a user's domain -- if it isn't valid, log them out
export default async function verifyDomain() {
  try {
    const domain = localStorage.getItem('domain');
    await axios.get(`/oauth/${domain}`);
    //console.log(`domain is ${domain}`);
  } catch (error) {
    alert('Invalid domain. Redirecting to login page.');
    localStorage.clear();
    window.location = '/login';
  }
}
