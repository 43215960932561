import React, { useState, useEffect } from 'react';
import { Line, Chart } from 'react-chartjs-2';
import { Button, Dialog, DialogTitle, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS, registerables } from 'chart.js';
import * as global from '../../../chartConfig/Defaults';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import GetAppIcon from '@material-ui/icons/GetApp';

ChartJS.register(...registerables, zoomPlugin);

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
    margin: theme.spacing(1), // Add some margin between buttons
  },
  chartContainer: {
    width: '100%',
    //height: '400px', // Set a fixed height or adjust as needed
  },
}));

const DailyObservations = ({ data, attribute }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  let units = 'unknown';
  let label = 'unknown';
  let colorMin = 'unknown';
  let colorAvg = 'unknown';
  let colorMax = 'unknown';
  if (attribute === 'temperature') {
    units = '°F';
    label = 'Temperature';
    colorMin = '#f99f9f';
    colorAvg = '#f55f5f';
    colorMax = '#d80e0e';
  } else if (attribute === 'humidity') {
    units = '%';
    label = 'Humidity';
    colorMin = '#9ccefc';
    colorAvg = '#42A1F9';
    colorMax = '#0776df';
  } else if (attribute === 'co2') {
    units = '';
    label = 'Co2(ppm)';
    colorMin = '#6ce077';
    colorAvg = '#29BF38';
    colorMax = '#1f932b';
  }

  const chartData = {
    labels: [
      ...data.map((day, index) => {
        const observationDate = new Date(day.observation_date);
        const formattedDate = observationDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        return `Day ${index + 1} - ${formattedDate}`;
      }),
    ],
    datasets: [
      {
        data: [...data.map((day) => day['timePdMin'])],
        label: 'Min',
        tension: 0.4,
        borderColor: colorMin,
        pointStyle: 'rect',
        pointRadius: global.pointRadius,
        hoverRadius: global.hoverRadius,
        backgroundColor: colorMin,
      },
      {
        data: [...data.map((day) => day['timePdAvg'])],
        label: 'Average',
        tension: 0.4,
        borderColor: colorAvg,
        pointStyle: 'circle',
        pointRadius: global.pointRadius,
        hoverRadius: global.hoverRadius,
        backgroundColor: colorAvg,
      },
      {
        data: [...data.map((day) => day['timePdMax'])],
        label: 'Max',
        tension: 0.4,
        borderColor: colorMax,
        pointStyle: 'triangle',
        pointRadius: global.pointRadius,
        hoverRadius: global.hoverRadius,
        backgroundColor: colorMax,
      },
    ],
  };

  const options = {
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          mode: 'xy',
          speed: 100,
          drag: {
            enabled: true,
            threshold: 10,
            mode: 'xy',
            onDragEnd: function ({ chart }) {
              const { left, right, top, bottom } = chart.chartArea;
              const { x, y } = chart.dragging;
              const minX = Math.min(x, chart.dragging.start.x);
              const maxX = Math.max(x, chart.dragging.start.x);
              const minY = Math.min(y, chart.dragging.start.y);
              const maxY = Math.max(y, chart.dragging.start.y);
              const rangeX = chart.scales.x.right - chart.scales.x.left;
              const rangeY = chart.scales.y.bottom - chart.scales.y.top;

              const zoomOptions = {
                x: {
                  min: chart.scales.x.getValueForPixel(left + minX * rangeX),
                  max: chart.scales.x.getValueForPixel(left + maxX * rangeX),
                },
                y: {
                  min: chart.scales.y.getValueForPixel(bottom - maxY * rangeY),
                  max: chart.scales.y.getValueForPixel(bottom - minY * rangeY),
                },
              };

              chart.resetZoom(); // RESET ZOOM LEVEL
              chart.zoom(zoomOptions); // ZOOM TO SELECTED REGION
            },
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value.toFixed(1) + units;
          },
        },
        title: {
          display: true,
          text: label,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
    },
  };

  const chartRef = React.useRef(null);
  const dialogChartRef = React.useRef(null);

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const handleDialogResetZoom = () => {
    if (dialogChartRef && dialogChartRef.current) {
      dialogChartRef.current.resetZoom();
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDownload = (chartReference) => {
    if (chartReference && chartReference.current) {
      const link = document.createElement('a');
      link.href = chartReference.current.toBase64Image();
      link.download = 'averages_chart.png';
      link.click();
    }
  };

  return (
    <div>
      <div className={classes.chartContainer}>
        <Line ref={chartRef} options={options} data={chartData} />
      </div>
      <Button className={classes.addButton} onClick={handleResetZoom}>
        Reset Zoom
      </Button>
      <Button className={classes.addButton} startIcon={<SettingsOverscanIcon />} onClick={handleDialogOpen}>
        Expand Chart
      </Button>
      <Button className={classes.addButton} startIcon={<GetAppIcon />} onClick={() => handleDownload(chartRef)}>
        Download
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Expanded Chart
          <IconButton onClick={handleDialogClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={classes.dialogContent}>
          <div className={classes.dialogChartContainer}>
            <Line ref={dialogChartRef} options={options} data={chartData} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DailyObservations;
