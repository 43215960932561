import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'; // Icon for the active menu item
import { Grid } from '@material-ui/core';

//Our Side Navbar Icons and Logo
import HomeIcon from '@material-ui/icons/Home';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MarsFarmLogo from './images/MarsFarmLogo.png';
import EcoIcon from '@material-ui/icons/Eco';
import ImageIcon from '@material-ui/icons/Image';
import AssessmentIcon from '@material-ui/icons/Assessment'; //trial analysis
import PublishIcon from '@material-ui/icons/Publish';
import BuildIcon from '@material-ui/icons/Build';
import RouterIcon from '@material-ui/icons/Router';
import TocIcon from '@material-ui/icons/Toc';

//Components
import Logout from './Logout';
import TrialDropdown from './TrialDropdown';
import ExperimentDropdown from './ExperimentDropdown';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#f5f5F5',
    height: 64,
    //height: theme.mixins.toolbar.minHeight,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar, //this is the spread operator
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
  },
  text: {
    color: theme.palette.grey[800],
  },
  logo: {
    maxWidth: 40,
    marginLeft: 'auto' /*aligns to right side*/,
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  activeListItem: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  chevronIcon: {
    marginLeft: 'auto',
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const location = useLocation(); // To determine which route is currently active

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //Material UI Icons
  const icons = [
    <RouterIcon />,
    <BuildIcon />,
    <PublishIcon />,
    <WbSunnyIcon />,
    <ImageIcon />,
    <AssessmentIcon />,
  ];

  //Front end routes -- can see in App.js which components are rendered
  const routes = [
    '/',
    '/trialManager',
    '/recipeManager',
    '/viewenviron',
    '/images',
    '/charts',
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* This is the top navbar */}
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color={theme.palette.grey[800]}
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <ChevronRightIcon />
          </IconButton>
          <Logout />
          <Grid
            container
            direction='row'
            spacing={1}
            justifyContent='flex-end'
          >
              <ExperimentDropdown />
              <TrialDropdown />
          </Grid>
          <img src={MarsFarmLogo} className={classes.logo} />
        </Toolbar>
      </AppBar>

      {/* This is where the side navbar starts */}
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon color='black' />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          {[
            'Device Manager',
            'Trial Manager',
            'Recipe Manager',
            'Environmental Data',
            'Images',
            'Data Analysis',
          ].map((text, index) => (
            <Link
              to={routes[index]}
              style={{ textDecoration: 'none', color: 'black' }}
              key={text}
            >
              <ListItem
                button
                key={text}
                className={clsx({
                  [classes.listItem]: true,
                  [classes.activeListItem]: location.pathname === routes[index],
                })}
              >
                <ListItemIcon>{icons[index]}</ListItemIcon>
                <ListItemText primary={text} />
                {location.pathname === routes[index] && (
                  <KeyboardArrowRightIcon className={classes.chevronIcon} />
                )}
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
      </main>
    </div>
  );
};

export default Navbar;
