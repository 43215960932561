import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import MarsFarmSvg from '../images/icon.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//Need to add for environmental variables
require('dotenv').config();

const onFailure = (response) => {
  localStorage.clear();
  window.location = '/login';
  console.log(response);
};

//Extracting the organization name from the email with a regex
const extractDomain = (email) => {
  return email.match(/(?<=@)[^.]+(?=\.)/);
};

const onSuccess = async (response) => {
  const org = extractDomain(response.profileObj.email);
  localStorage.clear();

  try {
    //use this to test, it will OVERWRITE userAuth and domain fields in localStorage
    //localStorage.setItem('userAuth', JSON.stringify('peter@marsfarm.com'));
    //localStorage.setItem('domain', JSON.stringify('marsfarm'));
    //Storing in local storage
    localStorage.setItem('userAuth', JSON.stringify(response));
    localStorage.setItem('domain', JSON.stringify(org));
    localStorage.setItem('currTrial', undefined);
    console.log('Login crendentials of', JSON.stringify(response), ' identified as part of the organization: ', JSON.stringify(org));

    //Redirect login
    window.location = '/';
  } catch (error) {
    console.log(error);
    localStorage.clear();
    alert('Invalid login credentials. Redirecting back to login page.');
    window.location = '/login';
  }
};

const useStyles = makeStyles({
  logo: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    backgroundColor: '#d9d9d9', // Replace with your app's button color
    color: 'Black',
    padding: '12px 24px',
    margin: '8px 0',
    border: '2px solid #3b3b3b', // Optional: add a border if common in your app
    borderRadius: '6px', // Match the border radius with other buttons
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#8f8f8f', // Replace with the hover color used in your app
    },
  },
});

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState(''); // State to hold the email input
  const [showEmailField, setShowEmailField] = useState(false); // State to control visibility of email field

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update state with the input
  };

  const handleCustomLogin = (event) => {
    event.preventDefault(); // Prevent the default form submit action
  
    // Extracting the domain (organization name) from the email
    const org = extractDomain(email);
  
    try {
      // Storing the necessary information in local storage
      localStorage.setItem('userAuth', JSON.stringify({ email: email }));
      localStorage.setItem('domain', JSON.stringify(org));
      localStorage.setItem('currTrial', undefined);
      console.log('Custom login credentials of', email, ' identified as part of the organization: ', org);
  
      // Redirect or further actions
      window.location = '/'; // Redirect to the home page or another page as needed
    } catch (error) {
      console.log(error);
      localStorage.clear();
      alert('Invalid login credentials. Redirecting back to login page.');
      window.location = '/login';
    }
  };

  const toggleEmailField = () => {
    setShowEmailField(!showEmailField); // Toggle the visibility of the email field
  };

  return (
    <div style={{ textAlign: 'center', margin: '20%' }}>
      <img src={MarsFarmSvg} alt='MARSfarm Logo' className={classes.logo} />
      <div>
        {/* Google Login button with custom style */}
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText='Login with Google'
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          render={renderProps => (
            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={classes.buttonStyle}>
              Login with Google
            </button>
          )}
        />
      </div>
      <div>
        {/* Button to toggle email field */}
        <button onClick={toggleEmailField} className={classes.buttonStyle}>
          Login with Email
        </button>

        {/* Conditionally rendered email field */}
        {showEmailField && (
          <form onSubmit={handleCustomLogin}>
            <input 
              type="email" 
              value={email} 
              onChange={handleEmailChange} 
              placeholder="Enter your email" 
              // Add any additional styling or attributes here
            />
            <button type="submit" className={classes.buttonStyle}>
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
