import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Card, CardContent, CardMedia, CardActions, Button, Typography, CircularProgress, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  dialogCard: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
    transform: 'rotate(180deg)', // Flip the image
  },
  dialogActions: {
    justifyContent: 'center',
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px', // Adjust height as needed
    width: '100%',
  },
  dialogImage: {
    width: '100%',
    height: 'auto',
    transform: 'rotate(180deg)',
  },
}));

const HourlyImagesOverlay = ({ open, onClose, trialID, dayNumber }) => {
  const [hourlyImages, setHourlyImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const classes = useStyles();
  const awsUrl = 'https://mv1-production.s3.us-east-2.amazonaws.com/';

  useEffect(() => {
    if (open) {
      const fetchHourlyImages = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`/getImages/${trialID}/day/${dayNumber}`);
          setHourlyImages(response.data);
        } catch (error) {
          console.error('Error fetching hourly images:', error);
        }
        setLoading(false);
      };

      fetchHourlyImages();
    }
  }, [open, trialID, dayNumber]);

  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split('_');
    const year = datePart.slice(0, 4);
    const month = datePart.slice(5, 7);
    const day = datePart.slice(8, 10);
    const hours = timePart.slice(0, 2);
    const minutes = timePart.slice(2, 4);
    return new Date(year, month - 1, day, hours, minutes).toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleClickOpenImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setSelectedImage('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Hourly Images: Day {dayNumber}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : (
          hourlyImages.map((image, index) => (
            <Card key={index} className={classes.dialogCard}>
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Date: {formatDate(image.Metadata.currtime)}
                </Typography>
              </CardContent>
              <CardMedia
                className={classes.media}
                component="img"
                image={`${awsUrl + image.Key}`}
                title={`Image from ${formatDate(image.Metadata.currtime)}`}
                onClick={() => handleClickOpenImage(`${awsUrl + image.Key}`)}
              />
              <CardActions className={classes.dialogActions}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  href={`${awsUrl + image.Key}`}
                  download
                  startIcon={<GetAppIcon />}
                  className={classes.button}
                >
                  Download
                </Button>
              </CardActions>
            </Card>
          ))
        )}
      </DialogContent>
      <Dialog open={imageDialogOpen} onClose={handleImageDialogClose} maxWidth="md">
        <DialogContent>
          <img src={selectedImage} alt="Enlarged view" className={classes.dialogImage} />
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default HourlyImagesOverlay;
