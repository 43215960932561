import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { GoogleLogout } from 'react-google-login';
//Need to add for environmental variables
require('dotenv').config();

const Logout = () => {
  const logout = () => {
    localStorage.clear();
    window.location = '/login';
  };

  return (
    <div style={{ margin: '2vh' }}>
      <GoogleLogout
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText='Logout'
        onLogoutSuccess={logout}
      ></GoogleLogout>
    </div>
  );
};

export default Logout;
