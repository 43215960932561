import React, { createContext, useContext, useState } from 'react';

const TrialContext = createContext();

export const useSelectedTrial = () => useContext(TrialContext);

export const TrialProvider = ({ children }) => {
  const [selectedTrial, setSelectedTrial] = useState(null);

  return (
    <TrialContext.Provider value={{ selectedTrial, setSelectedTrial }}>
      {children}
    </TrialContext.Provider>
  );
};
