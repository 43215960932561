import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  Grid,
} from '@material-ui/core';
import axios from 'axios';
import verifyDomain from '../../functions/verifyDomain';
import { useSelectedTrial } from '../../contexts/TrialContext'; // Update the import path as needed

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    paddingBottom: '2%',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
  tableContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));


const EnvironmentalData = () => {
  const classes = useStyles();
  const { selectedTrial } = useSelectedTrial(); // Use context to get the selected trial
  const [environData, setEnvironData] = useState([]);
  const [observationType, setObservationType] = useState('temperature');
  const [timeFrame, setTimeFrame] = useState('Daily');

  verifyDomain(); // Function kicks user out if they aren't logged in with a valid domain

  const handleAttributeSelection = (e, attribute) => {
    setObservationType(attribute);
  };

  const handleTimeFrame = (e, time) => {
    setTimeFrame(time);
  };

  useEffect(() => {
    async function getEnvironData() {
      if (selectedTrial && selectedTrial._id) { // Make sure selectedTrial is not null and has _id
        const req = await axios.get(`/environment/${selectedTrial._id}`, {
          params: { attribute: observationType, timeFrame },
        });
        setEnvironData(req.data);
      } else {
        console.log('Trial is undefined, cannot get environmental data');
      }
    }
    getEnvironData();
  }, [selectedTrial, observationType, timeFrame]);

  if (!selectedTrial || selectedTrial === 'undefined')
    return (
      <Typography variant='h4' style={{ marginTop: '20%' }}>
        Please select a trial to view environmental data. Select from the trial
        dropdown in the top right. Or, if you haven't started a trial, create a
        new trial <Link to='/'>here</Link>.
      </Typography>
    );
  else
    return (
      <>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='p'>
              Time Frame: <strong>{timeFrame}</strong>
            </Typography>
            <br />
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                onClick={(e) => {
                  handleTimeFrame(e, 'Raw');
                }}
              >
                Raw
              </Button>
              <Button
                onClick={(e) => {
                  handleTimeFrame(e, 'Daily');
                }}
              >
                Daily
              </Button>
              <Button
                onClick={(e) => {
                  handleTimeFrame(e, 'Weekly');
                }}
              >
                Weekly
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={6}>
            {/* Buttons to select attribute */}
            <Typography variant='p'>
              Current Attribute: <strong>{observationType}</strong>
            </Typography>
            <br />
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                onClick={(e) => {
                  handleAttributeSelection(e, 'co2');
                }}
              >
                Co2
              </Button>
              <Button
                onClick={(e) => {
                  handleAttributeSelection(e, 'temperature');
                }}
              >
                Temperature
              </Button>
              <Button
                onClick={(e) => {
                  handleAttributeSelection(e, 'humidity');
                }}
              >
                Humidity
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <TableContainer component={Paper} className={classes.tableContainer}>
          {timeFrame !== 'Raw' && (
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='right'>
                    Observation Date
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    Average {observationType}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    Minimum {observationType}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    Maximum {observationType}
                  </StyledTableCell>
                  {/* <StyledTableCell align='right'></StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {timeFrame !== 'Raw' &&
                  environData &&
                  //Only selecting top 50 so loading times aren't super slow
                  environData.slice(0, 50).map((observation, index) => (
                    <StyledTableRow key={observation._id}>
                      <StyledTableCell align='right'>
                        {/* Convert from either raw, daily, or weekly to a date, adding conversions below */}
                        {timeFrame === 'Daily' && `Day ${observation._id}`}
                        {timeFrame === 'Weekly' && `Week ${observation._id}`}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {observationType === 'temperature' &&
                          `${Number(observation.timePdAvg).toFixed(1)} °F`}
                        {observationType === 'humidity' &&
                          `${Number(observation.timePdAvg).toFixed(1)}%`}
                        {observationType === 'co2' &&
                          `${Number(observation.timePdAvg).toFixed(1)} ppm`}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {observationType === 'temperature' &&
                          `${Number(observation.timePdMin).toFixed(1)} °F`}
                        {observationType === 'humidity' &&
                          `${Number(observation.timePdMin).toFixed(1)}%`}
                        {observationType === 'co2' &&
                          `${Number(observation.timePdMin).toFixed(
                            1
                          )} ppm`}{' '}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {observationType === 'temperature' &&
                          `${Number(observation.timePdMax).toFixed(1)} °F`}
                        {observationType === 'humidity' &&
                          `${Number(observation.timePdMax).toFixed(1)}%`}
                        {observationType === 'co2' &&
                          `${Number(observation.timePdMax).toFixed(1)} ppm`}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {timeFrame === 'Raw' && (
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='right'>
                    Raw Observation Date
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {observationType}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {environData &&
                  //Only selecting top 50 so loading times aren't super slow
                  environData.slice(0, 50).map((observation, index) => (
                    <StyledTableRow key={observation._id}>
                      <StyledTableCell align='left'>
                        {/* Convert from either raw, daily, or weekly to a date, adding conversions below */}
                        {observation.observation_date}
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        {observation.attribute === 'temperature' &&
                          `${observation.value} °F`}
                        {observation.attribute === 'humidity' &&
                          `${observation.value}%`}
                        {observation.attribute === 'co2' &&
                          `${observation.value} ppm`}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </>
    );
};

export default EnvironmentalData;
