import React, { useState, useEffect } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import { Button, Dialog, DialogTitle, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import 'chartjs-adapter-moment';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as global from '../../../chartConfig/Defaults';

Chart.register(zoomPlugin);

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
    margin: theme.spacing(1), // Add some margin between buttons
  },
  chartContainer: {
    width: '100%',
    //height: '400px', // Set a fixed height or adjust as needed
  },
}));

const PumpActuator = ({ data, attribute }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  // Group the values by 'day_number'
  const groupedData = {};
  data.forEach((day) => {
    const { day_number, value, observation_date } = day;
    if (!groupedData[day_number]) {
      groupedData[day_number] = { values: [], time: moment(observation_date) };
    }
    groupedData[day_number].values.push(value);
  });

  // Extract unique 'day_number' values as chart labels
  const chartLabels = Object.keys(groupedData);

  // Determine the maximum number of doses within a day
  const maxDoses = Math.max(...chartLabels.map(label => groupedData[label].values.length));

  // Formatting based on the attribute (pump, etc.)
  let units = 'unknown';
  let label = 'unknown';
  let color = 'unknown';
  if (attribute === 'pump') {
    units = '';
    label = 'Pump Actuator (mL)';
    color = '#0776df';
  }

  // Create datasets with individual colors for each value within 'day_number' group
  const datasets = Array.from({ length: maxDoses }, (_, index) => {
    const values = chartLabels.map(label => groupedData[label].values[index] || 0); // Fill missing values with 0
    const backgroundColor = global.backgroundColorArray[index % global.backgroundColorArray.length];
    return {
      label: `Dose ${index + 1}`,
      data: values,
      backgroundColor: backgroundColor,
      stack: 'barStack',
    };
  });

  // creates day labels and makes sure that they do not repeat
  const uniqueDayLabels = chartLabels.map(label => {
    return groupedData[label].time;
  });

  const chartData = {
    labels: uniqueDayLabels,
    datasets: [...datasets],
  };

  const options = {
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          mode: 'xy',
          speed: 100,
          drag: {
            enabled: true,
            threshold: 10,
            mode: 'xy',
            onDragEnd: function ({ chart }) {
              const { left, right, top, bottom } = chart.chartArea;
              const { x, y } = chart.dragging;
              const minX = Math.min(x, chart.dragging.start.x);
              const maxX = Math.max(x, chart.dragging.start.x);
              const minY = Math.min(y, chart.dragging.start.y);
              const maxY = Math.max(y, chart.dragging.start.y);
              const rangeX = chart.scales.x.right - chart.scales.x.left;
              const rangeY = chart.scales.y.bottom - chart.scales.y.top;

              const zoomOptions = {
                x: {
                  min: chart.scales.x.getValueForPixel(left + minX * rangeX),
                  max: chart.scales.x.getValueForPixel(left + maxX * rangeX),
                },
                y: {
                  min: chart.scales.y.getValueForPixel(bottom - maxY * rangeY),
                  max: chart.scales.y.getValueForPixel(bottom - minY * rangeY),
                },
              };

              chart.resetZoom(); // RESET ZOOM LEVEL
              chart.zoom(zoomOptions); // ZOOM TO SELECTED REGION
            },
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        min: 0,
        suggestedMax: 500,
        stacked: true,
        ticks: {
          callback: function (value) {
            return value.toFixed(1) + units;
          },
        },
        title: {
          display: true,
          text: label,
        },
      },
      y2: {
        position: 'right',
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: true,
          text: 'Humidity (%)', // Right Y-axis label
        },
      },
      x: {
        type: 'time', // Set x-axis scale to time
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM D, YYYY',
          },
          tooltipFormat: 'MMM D, YYYY h:mm A', // Format for tooltips
        },
        stacked: true,
        title: {
          display: true,
          text: 'Time',
        },
        offset: true, // Add extra space on both sides of the chart
        ticks: {
          source: 'data', // Distribute ticks based on data points
          maxRotation: 0, // Rotate labels to avoid overlapping
        },
      },
    },
  };

  const chartRef = React.useRef(null);
  const dialogChartRef = React.useRef(null);

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const handleDialogResetZoom = () => {
    if (dialogChartRef && dialogChartRef.current) {
      dialogChartRef.current.resetZoom();
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDownload = (chartReference) => {
    if (chartReference && chartReference.current) {
      const link = document.createElement('a');
      link.href = chartReference.current.toBase64Image();
      link.download = 'pump_chart.png';
      link.click();
    }
  };

  return (
    <div>
      <div className={classes.chartContainer}>
        <Bar ref={chartRef} options={options} data={chartData} />
      </div>
      <Button className={classes.addButton} onClick={handleResetZoom}>
        Reset Zoom
      </Button>
      <Button className={classes.addButton} startIcon={<SettingsOverscanIcon />} onClick={handleDialogOpen}>
        Expand Chart
      </Button>
      <Button className={classes.addButton} startIcon={<GetAppIcon />} onClick={() => handleDownload(chartRef)}>
        Download
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Expanded Chart
          <IconButton onClick={handleDialogClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={classes.dialogContent}>
          <div className={classes.dialogChartContainer}>
            <Bar ref={dialogChartRef} options={options} data={chartData} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PumpActuator;
