// ExperimentContext.js
import React, { createContext, useState, useContext } from 'react';

const ExperimentContext = createContext();

export const useSelectedExperiment = () => useContext(ExperimentContext);

export const ExperimentProvider = ({ children }) => {
  const [selectedExperiment, setSelectedExperiment] = useState(null); // Initialize with null or from localStorage

  return (
    <ExperimentContext.Provider value={{ selectedExperiment, setSelectedExperiment }}>
      {children}
    </ExperimentContext.Provider>
  );
};
