//Global configuration for Chart.js

export const pointRadius = 6;
export const hoverRadius = 8;
export const defaultFont = 'Roboto';
export const defaultFontSize = 14;
export const tension =0.4;
export const backgroundColorArray = [
  'rgba(255, 99, 132, 0.7)',
  'rgba(54, 162, 235, 0.7)',
  'rgba(255, 206, 86, 0.7)',
  'rgba(75, 192, 192, 0.7)',
  'rgba(153, 102, 255, 0.7)',
  'rgba(255, 159, 64, 0.7)',
  'rgba(255, 69, 0, 0.7)',
  'rgba(0, 128, 0, 0.7)',
  'rgba(128, 0, 128, 0.7)',
  'rgba(0, 139, 139, 0.7)',
  'rgba(255, 215, 0, 0.7)',
  'rgba(30, 144, 255, 0.7)',
  'rgba(178, 34, 34, 0.7)',
  'rgba(0, 255, 255, 0.7)',
  'rgba(240, 128, 128, 0.7)',
  'rgba(0, 0, 139, 0.7)',
  'rgba(219, 112, 147, 0.7)',
];
export const zoomOptions = {
  // Your zoom options configuration
  zoom: {
    wheel: {
      enabled: true, // Enable zooming with mouse wheel
    },
    pinch: {
      enabled: true, // Enable zooming with pinch gesture
    },
    mode: 'xy', // Allow zooming in both the x and y directions
  },
  // Other options...
};