import React, { useState, useEffect } from 'react';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Typography, CircularProgress, Box, Dialog, DialogContent, Card, CardContent, CardMedia, CardActions, Button } from '@material-ui/core';
import axios from 'axios';
import verifyDomain from '../../functions/verifyDomain';
import { useSelectedTrial } from '../../contexts/TrialContext';
import GetAppIcon from '@material-ui/icons/GetApp';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HourlyImagesOverlay from '../HourlyImagesOverlay'; // Import the new component

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  noImagesText: {
    textAlign: 'center',
    marginTop: theme.spacing(10),
  },
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0 15px 30px rgba(0, 0, 0, 0.3)',
    },
  },
  mediaContainer: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%',
    overflow: 'hidden',
  },
  media: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  flippedImage: {
    transform: 'rotate(180deg)',
  },
  dialogImage: {
    width: '100%',
    height: 'auto',
    transform: 'rotate(180deg)',
  },
  cardContent: {
    paddingBottom: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const greenTheme = createTheme({
  palette: {
    primary: {
      main: '#008000',
    },
  },
});

const Images = () => {
  const [allImages, setAllImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage the new overlay component
  const [selectedDay, setSelectedDay] = useState(null); // State to manage the selected day
  const classes = useStyles();
  const { selectedTrial } = useSelectedTrial();
  const awsUrl = 'https://mv1-production.s3.us-east-2.amazonaws.com/';

  verifyDomain();

  useEffect(() => {
    const getImages = async () => {
      try {
        if (selectedTrial && selectedTrial._id) {
          setImagesLoaded(false);
          const req = await axios.get(`/getImages/${selectedTrial._id}`);
          setAllImages([...req.data]);
          setImagesLoaded(true);
        }
      } catch (error) {
        console.error(error);
        setImagesLoaded(true);
      }
    };

    if (selectedTrial && selectedTrial._id) {
      getImages();
    }
  }, [selectedTrial]);

  const handleClickOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const handleViewDay = (trialID, dayNumber) => {
    setSelectedDay(dayNumber);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedDay(null);
  };

  if (!selectedTrial)
    return (
      <Typography variant='h4' style={{ marginTop: '20%' }}>
        Please select a trial to view images. Select from the trial dropdown in the top right. Or, if you haven't started a trial, create a new trial.
      </Typography>
    );

  return (
    <ThemeProvider theme={greenTheme}>
      {!imagesLoaded ? (
        <Box className={classes.loadingContainer}>
          <Typography variant="h6" gutterBottom>
            Loading images for the selected trial...
          </Typography>
          <CircularProgress />
        </Box>
      ) : allImages.length === 0 ? (
        <Typography variant="h6" className={classes.noImagesText}>
          No images received for the selected Trial. If this is a newly created Trial, please wait up to an hour for images to begin sending. If this is not a newly created Trial, ensure the device is connected to the internet.
        </Typography>
      ) : (
        <div className={classes.root}>
          {allImages.map((item, index) => (
            <Card key={index} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Day Number: {item.Metadata.day_number || 'Invalid Day Num'}
                </Typography>
              </CardContent>
              <div className={classes.mediaContainer}>
                <CardMedia
                  className={`${classes.media} ${classes.flippedImage}`}
                  image={`${awsUrl + item.Key}?w=164&h=164&fit=crop&auto=format`}
                  title={`Day Number: ${item.Metadata.day_number}`}
                  onClick={() => handleClickOpen(awsUrl + item.Key)}
                />
              </div>
              <CardActions className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<CalendarTodayIcon />}
                  className={classes.button}
                  onClick={() => handleViewDay(selectedTrial._id, item.Metadata.day_number)}
                >
                  Hourly Images
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  href={`${awsUrl + item.Key}`}
                  download
                  startIcon={<GetAppIcon />}
                  className={classes.button}
                >
                  Download
                </Button>
              </CardActions>
            </Card>
          ))}
          <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogContent>
              <img src={selectedImage} alt="Enlarged view" className={classes.dialogImage} />
            </DialogContent>
          </Dialog>
          {dialogOpen && selectedDay && (
            <HourlyImagesOverlay 
              open={dialogOpen} 
              onClose={handleDialogClose} 
              trialID={selectedTrial._id} 
              dayNumber={selectedDay} 
            />
          )}
        </div>
      )}
    </ThemeProvider>
  );
};

export default Images;
