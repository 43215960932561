import './App.css';
import Navbar from './components/Navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ExperimentManager from './components/pages/ExperimentManager.js';
import TrialManager from './components/pages/TrialManager.js';
import RecipeManager from './components/pages/RecipeManager.js';
import DeviceManager from './components/pages/DeviceManager.js';
import EnvironmentalData from './components/pages/EnvironmentData';
import Images from './components/pages/Images';
import Login from './components/pages/Login';
import Analysis from './components/pages/Analysis';

import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  App: {
    display: 'flex',
    textAlign: 'center',
    //marginTop: '7vh'
    //marginTop: theme.mixins.toolbar.minHeight,
    marginTop: 64, // Toggle for testing
    //marginRight: '8%', // Can be removed
  },
  pages: {
    flexGrow: 1, //necessary for making the page adjust when appbar is expanded
    //padding: theme.spacing(1),
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={Login} />
        <div className={classes.App}>
          <Navbar />
          <div className={classes.pages}>
            <Route exact path='/' component={DeviceManager} />
            <Route exact path='/trialManager' component={TrialManager} />
            <Route exact path='/recipeManager' component={RecipeManager} />
            <Route exact path='/viewenviron' component={EnvironmentalData} />
            <Route exact path='/images' component={Images} />
            <Route exact path='/charts' component={Analysis} />
            <Redirect to='/' />
          </div>
        </div>
      </Switch>
    </Router>
  );
}

export default App;
