import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Button, Box } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import verifyDomain from '../../functions/verifyDomain';
import { useSelectedExperiment } from '../../contexts/ExperimentContext';

// Define styles for table cells and rows
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// Define styles for the table and button
const useStyles = makeStyles((theme) => ({
  table: {
    //minWidth: 700,
  },
  addButton: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  container: {
    maxHeight: '80vh',
    boxShadow: theme.shadows[5],
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
  },
  addButtonContainer: {
    paddingRight: theme.spacing(4), // Add padding to the right
  },
}));


const RecipeManager = () => {
  const classes = useStyles();
  const [recipes, setRecipes] = useState([]);
  const fileInputRef = useRef(null);
  const { selectedExperiment: experiment } = useSelectedExperiment();

  //Function kicks user out if they aren't logged in with a valid domain
  verifyDomain();

  // Fetch recipes from the server when the component mounts
  useEffect(() => {
    const experimentId = experiment?._id;
  
    const fetchRecipes = async () => {
      if (experimentId) {
        try {
          const response = await axios.get(`/templateRecipes/byExperiment/${experimentId}`);
          // Sort recipes alphabetically by recipe_name
          const sortedRecipes = response.data.sort((a, b) => {
            return a.recipe_name.localeCompare(b.recipe_name);
          });
          setRecipes(sortedRecipes);
        } catch (error) {
          console.error('Failed to fetch recipes:', error);
        }
      }
    };
  
    fetchRecipes();
  }, [experiment]);  

  const handleAddRecipeClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file); // Log immediately to verify this function is called
    if (file && file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        console.log('File read:', e.target.result); // Confirm file reading
        let recipeData = JSON.parse(e.target.result);
  
        // Remove the _id property if it exists
        if (recipeData._id) {
          delete recipeData._id;
        }
  
        const experimentID = experiment?._id;
        const url = `templateRecipes/addRecipe/${experimentID}`;
        try {
          // Send the modified recipe data without the _id
          const response = await axios.post(url, recipeData, {
            headers: {'Content-Type': 'application/json'}
          });
          console.log('Recipe added:', response.data);
          // Optionally, update your state or UI here to reflect the addition of the new recipe
        } catch (error) {
          console.error('Error sending the file:', error);
          // Consider providing user feedback here, e.g., displaying an error message
        }
      };
      reader.onerror = (error) => console.error('Error reading file:', error);
      reader.readAsText(file);
    } else {
      console.log('Invalid file type selected.');
      alert('Please select a JSON file.');
    }
  };
  
  
  

  // Function to handle the download button click
  const handleDownloadClick = async (recipeId) => {
    try {
      // Fetch the specific recipe using its ID
      const response = await axios.get(`/templateRecipes/${recipeId}`);
      const recipe = response.data;

      // Create a Blob containing the JSON data
      const blob = new Blob([JSON.stringify(recipe)], { type: 'application/json' });

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${recipe.recipe_name}.json`; // Set the filename to the recipe_name
      a.style.display = 'none';

      // Append the anchor element to the document and trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up the temporary URL and anchor element
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Failed to download recipe:', error);
    }
  };

  // Function to handle adding a new recipe
  // Leave this function empty for now, as per your request
  const handleAddRecipe = async () => {
    console.log('Add recipe clicked');
    // Implementation will go here
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" p={2} className={classes.addButtonContainer}>
        <Button
          variant="contained"
          className={classes.addButton}
          onClick={handleAddRecipeClick}
        >
          Upload New Recipe
        </Button>
        <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".json"
        />
      </Box>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Recipe Name</StyledTableCell>
                <StyledTableCell align="left">Recipe Summary</StyledTableCell>
                <StyledTableCell align="center">Download</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recipes.map((recipe) => (
                <StyledTableRow key={recipe._id}>
                  <StyledTableCell component="th" scope="row">{recipe.recipe_name}</StyledTableCell>
                  <StyledTableCell align="left">{recipe.recipe_variable}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => handleDownloadClick(recipe._id)}
                      style={{ backgroundColor: 'gray', color: 'white' }}
                    >
                      Download
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

    </>
  );
};

export default RecipeManager;
