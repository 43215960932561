import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { useSelectedExperiment } from '../contexts/ExperimentContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      minWidth: '5rem',
      maxWidth: '5rem',
      '& label': {
        fontSize: '0.7rem', // smaller text for labels on extra-small devices
      },
      '& .MuiSelect-select': {
        fontSize: '0.7rem', // smaller text for selected item on extra-small devices
      }
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '5rem',
      maxWidth: '5rem',
      '& label': {
        fontSize: '0.8rem', // medium text for labels on small devices
      },
      '& .MuiSelect-select': {
        fontSize: '0.8rem', // medium text for selected item on small devices
      }
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '9rem',
      maxWidth: '12rem',
      '& label': {
        fontSize: '0.9rem', // larger text for labels on medium devices
      },
      '& .MuiSelect-select': {
        fontSize: '0.9rem', // larger text for selected item on medium devices
      }
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '12rem',
      maxWidth: '16rem',
      '& label': {
        fontSize: '1rem', // largest text for labels on large devices
      },
      '& .MuiSelect-select': {
        fontSize: '1rem', // largest text for selected item on large devices
      }
    }
  },
}));

const ExperimentDropdown = () => {
  const classes = useStyles();
  const { selectedExperiment, setSelectedExperiment } = useSelectedExperiment(); // Use context to get and set the selected experiment
  const [experiments, setExperiments] = useState([]);
  const domain = JSON.parse(localStorage.getItem('domain') || '[]')[0]; // Get the first domain from localStorage

  useEffect(() => {
    async function fetchExperiments() {
      if (domain) {
        try {
          const response = await axios.get(`/experiments/organizationExperiments?orgName=${domain}`);
          const fetchedExperiments = response.data;
          // Sort experiments alphabetically by experiment_name
          fetchedExperiments.sort((a, b) => a.experiment_name.localeCompare(b.experiment_name));
          setExperiments(fetchedExperiments);
          // Automatically select the first experiment if there's no selected experiment yet
          if (fetchedExperiments.length > 0 && !selectedExperiment) {
            setSelectedExperiment(fetchedExperiments[0]);
          }
        } catch (error) {
          console.error('Error fetching experiment data:', error);
        }
      }
    }
    fetchExperiments();
  }, [domain, selectedExperiment, setSelectedExperiment]);
  
  

  // Find the index of the selected experiment in the experiments array
  const selectedExperimentIndex = selectedExperiment ? experiments.findIndex(exp => exp._id === selectedExperiment._id) : '';

  const handleExperimentSelection = (event) => {
    const index = event.target.value;
    const experimentInfo = experiments[index];
    setSelectedExperiment(experimentInfo); // Update context with the selected experiment info
    console.log('Selected experiment:', selectedExperiment);
  };

  return (
    <div>
      <FormControl variant="outlined" size='small' className={classes.formControl}>
        <InputLabel id="experiment-select-label">Select an Experiment</InputLabel>
        <Select
          labelId="experiment-select-label"
          id="experiment-select"
          value={selectedExperimentIndex}
          onChange={handleExperimentSelection}
          label="Selected Experiment"
        >
          {experiments.map((experiment, index) => (
            <MenuItem key={experiment._id} value={index}>
              {experiment.experiment_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ExperimentDropdown;
