import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ExperimentProvider } from './contexts/ExperimentContext';
import { TrialProvider } from './contexts/TrialContext';
// import theme from './themes/theme';

//Video to watch later: https://www.youtube.com/watch?v=xIIJfmDnvPE
//Defualt theme info and how themes are structured: https://material-ui.com/customization/default-theme/#default-theme
//Access the specific aspects (ex: grey 600 as grey[600] bc in an array of it)
//You can use the below block of code to edit something or put it on a separate page and import it
const theme = createMuiTheme({
  palette: {
    type: 'light',
    // grey: {
    //   600: '#757575',
    // },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ExperimentProvider>
      <TrialProvider>
        <App />
      </TrialProvider>
    </ExperimentProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

