import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { useSelectedTrial } from '../contexts/TrialContext';
import { useSelectedExperiment } from '../contexts/ExperimentContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      minWidth: '5rem',
      maxWidth: '5rem',
      '& label': {
        fontSize: '0.7rem', // smaller text for labels on extra-small devices
      },
      '& .MuiSelect-select': {
        fontSize: '0.7rem', // smaller text for selected item on extra-small devices
      }
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '5rem',
      maxWidth: '5rem',
      '& label': {
        fontSize: '0.8rem', // medium text for labels on small devices
      },
      '& .MuiSelect-select': {
        fontSize: '0.8rem', // medium text for selected item on small devices
      }
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '9rem',
      maxWidth: '12rem',
      '& label': {
        fontSize: '0.9rem', // larger text for labels on medium devices
      },
      '& .MuiSelect-select': {
        fontSize: '0.9rem', // larger text for selected item on medium devices
      }
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '12rem',
      maxWidth: '16rem',
      '& label': {
        fontSize: '1rem', // largest text for labels on large devices
      },
      '& .MuiSelect-select': {
        fontSize: '1rem', // largest text for selected item on large devices
      }
    }
  },
}));

const TrialDropdown = () => {
  const classes = useStyles();
  const { selectedTrial, setSelectedTrial } = useSelectedTrial();
  const [trials, setTrials] = useState([]);
  const { selectedExperiment } = useSelectedExperiment();

  useEffect(() => {
    async function fetchTrials() {
      if (selectedExperiment) {
        const response = await axios.get(`/trials/exp/${selectedExperiment._id}`);
        // Sort trials alphabetically by trial_name
        const sortedTrials = response.data.sort((a, b) => a.trial_name.localeCompare(b.trial_name));
        setTrials(sortedTrials);
      } else {
        setTrials([]);
      }
    }
    fetchTrials();
  }, [selectedExperiment]);

  useEffect(() => {
    if (trials.length > 0 && (!selectedTrial || !trials.find(trial => trial._id === selectedTrial._id))) {
      setSelectedTrial(trials[0]);
    }
  }, [trials, selectedTrial, setSelectedTrial]);

  const handleTrialSelection = (event) => {
    const trialId = event.target.value;
    const trialInfo = trials.find(trial => trial._id === trialId);
    setSelectedTrial(trialInfo);
  };

  return (
    <div>
      <FormControl variant="outlined" size='small' className={classes.formControl}>
        <InputLabel id="trial-select-label">Select a Trial</InputLabel>
          <Select
            labelId="trial-select-label"
            id="trial-select"
            value={selectedTrial?._id || ''}
            onChange={handleTrialSelection}
            label="Select a Trial"
          >
          {trials.map((trial) => (
            <MenuItem key={trial._id} value={trial._id}>
              {trial.trial_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TrialDropdown;
