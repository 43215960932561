import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ExperimentManager = () => {
  const classes = useStyles();
  const [experiments, setExperiments] = useState([]);
  const [trials, setTrials] = useState({}); // Store trials in an object, keyed by experiment ID

  const domain = JSON.parse(localStorage.getItem('domain') || '[]')[0];

  useEffect(() => {
    async function fetchExperiments() {
      if (domain) {
        try {
          const response = await axios.get(`/experiments/organizationExperiments?orgName=${domain}`);
          const fetchedExperiments = response.data;
          fetchedExperiments.sort((a, b) => a.experiment_name.localeCompare(b.experiment_name));
          setExperiments(fetchedExperiments);
        } catch (error) {
          console.error('Error fetching experiment data:', error);
        }
      }
    }
    fetchExperiments();
  }, [domain]);

  const fetchTrialsForExperiment = async (experimentId) => {
    try {
      // Corrected string to template literal for variable interpolation
      const response = await axios.get(`/trials/exp/${experimentId}`);
      setTrials((prevTrials) => ({
        ...prevTrials,
        [experimentId]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching trials for experiment ${experimentId}:`, error);
    }
  };

  const handleAccordionChange = (experimentId) => (event, isExpanded) => {
    if (isExpanded && !trials[experimentId]) {
      fetchTrialsForExperiment(experimentId);
    }
  };

  return (
    <div className={classes.root}>
      {experiments.map((experiment) => (
        <Accordion key={experiment._id} onChange={handleAccordionChange(experiment._id)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{experiment.experiment_name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography>
                Details for {experiment.experiment_name}
              </Typography>
              {/* Ensure trials[experiment._id] is an array before mapping */}
              {Array.isArray(trials[experiment._id]) && trials[experiment._id].map((trial) => (
                <Accordion key={trial._id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{trial.trial_name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Details for {trial.trial_name}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ExperimentManager;
