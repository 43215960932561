import React, { useState, useEffect } from 'react';
import { Line, Chart } from 'react-chartjs-2';
import { Button, Dialog, DialogTitle, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import randomColor from '../../../../functions/randomColor';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as global from '../../../chartConfig/Defaults';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import GetAppIcon from '@material-ui/icons/GetApp';
Chart.register(zoomPlugin);

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
    margin: theme.spacing(1), // Add some margin between buttons
  },
  chartContainer: {
    width: '100%',
    //height: '400px', // Set a fixed height
  },
}));

const HumidityExperimentEnvironmentalObservationChart = ({ data }) => {
  const classes = useStyles();
  const [datasets, setDatasets] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const maxLength = data.reduce((max, trial) => Math.max(max, trial.length), 0);

  useEffect(() => {
    let datasets = [];
    for (let i = 0; i < data.length; i++) {
      datasets.push({});
    }
    for (let i = 0; i < data.length; i++) {
      datasets[i]['data'] = data[i].map((obs) => obs['timePdAvg']);
      const currColor = randomColor();
      datasets[i]['borderColor'] = currColor;
      datasets[i]['backgroundColor'] = currColor;
      datasets[i]['pointStyle'] = 'rect';
      datasets[i]['label'] = `Trial ${data[i][0].trial_name}`;
      datasets[i]['pointRadius'] = global.pointRadius;
      datasets[i]['hoverRadius'] = global.hoverRadius;
    }
    setDatasets(datasets);
    console.log('From inside hook: ', datasets);
  }, [data]);

  const labels = Array.from({ length: maxLength }, (_, i) => `Day ${i + 1}`);

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    tension: 0.4,
    legend: {
      display: true,
      labels: {
        boxWidth: 14,
      },
    },
    title: {
      display: true,
      text: 'Average Humidity across all Trials in an Experiment',
      fontSize: 22,
    },
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value.toFixed(1) + ' %';
          },
        },
        title: {
          display: true,
          text: 'Humidity (%)',
          fontStyle: 'bold',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time since the trial began',
          fontStyle: 'bold',
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          mode: 'xy',
          speed: 100,
          drag: {
            enabled: true,
            threshold: 10,
            mode: 'xy',
            onDragEnd: function ({ chart }) {
              const { left, right, top, bottom } = chart.chartArea;
              const { x, y } = chart.dragging;
              const minX = Math.min(x, chart.dragging.start.x);
              const maxX = Math.max(x, chart.dragging.start.x);
              const minY = Math.min(y, chart.dragging.start.y);
              const maxY = Math.max(y, chart.dragging.start.y);
              const rangeX = chart.scales.x.right - chart.scales.x.left;
              const rangeY = chart.scales.y.bottom - chart.scales.y.top;

              const zoomOptions = {
                x: {
                  min: chart.scales.x.getValueForPixel(left + minX * rangeX),
                  max: chart.scales.x.getValueForPixel(left + maxX * rangeX),
                },
                y: {
                  min: chart.scales.y.getValueForPixel(bottom - maxY * rangeY),
                  max: chart.scales.y.getValueForPixel(bottom - minY * rangeY),
                },
              };
              chart.resetZoom();
              chart.zoom(zoomOptions);
            },
          },
        },
      },
    },
  };

  const chartRef = React.useRef(null);

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDownload = () => {
    if (chartRef && chartRef.current) {
      const link = document.createElement('a');
      link.href = chartRef.current.toBase64Image();
      link.download = 'HumidityChart.png';
      link.click();
    }
  };

  return (
    <div>
      <div className={classes.chartContainer}>
        <Line ref={chartRef} options={options} data={chartData} />
      </div>
      <Button className={classes.addButton} onClick={handleResetZoom}>Reset Zoom</Button>
      <Button className={classes.addButton} startIcon={<SettingsOverscanIcon />} onClick={handleDialogOpen}>Expand Chart</Button>
      <Button className={classes.addButton} startIcon={<GetAppIcon />} onClick={handleDownload}>Download</Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Expanded Chart
          <IconButton onClick={handleDialogClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={classes.dialogContent}>
          <div className={classes.dialogChartContainer}>
            <Line options={options} data={chartData} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default HumidityExperimentEnvironmentalObservationChart;
