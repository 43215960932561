import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Typography, Box, Paper } from '@material-ui/core';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { useSelectedExperiment } from '../../contexts/ExperimentContext';
import { useSelectedTrial } from '../../contexts/TrialContext';
import TemperatureExperimentEnvironmentalObservationChart from './ExperimentAnalysis/ExperimentEnvironmentalObservationChart/TemperatureExperimentEnvironmentalObservationChart';
import HumidityExperimentEnvironmentalObservationChart from './ExperimentAnalysis/ExperimentEnvironmentalObservationChart/HumidityExperimentEnvironmentalObservationChart';
import Co2ExperimentEnvironmentalObservationChart from './ExperimentAnalysis/ExperimentEnvironmentalObservationChart/Co2ExperimentEnvironmentalObservationChart';
import DailyObservations from './TrialAnalysis/TrialEnvironmentalObservationChart/DailyObservations';
import AllObservations from './TrialAnalysis/TrialEnvironmentalObservationChart/AllObservations';
import PumpActuator from './TrialAnalysis/TrialPumpObservationChart/Actuator';
import groupBy from '../../functions/groupBy';

const useStyles = makeStyles((theme) => ({
  responsiveText: {
    fontSize: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '24px',
    },
  },
  responsiveHeading: {
    fontSize: '0.5rem',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.6rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },
  responsiveRadio: {
    '& .MuiFormControlLabel-label': {
      fontSize: '0.4rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '0.7rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '0.9rem',
      },
    }
  },
}));

const greenTheme = createTheme({
  palette: {
    primary: {
      main: '#008000',
    },
  },
});

const Analysis = () => {
  const classes = useStyles();

  const [analysisMode, setAnalysisMode] = useState('SingleTrial');
  const [graphSelection, setGraphSelection] = useState('temperature');
  const [dataSlice, setDataSlice] = useState('AllData');
  const [dataReady, setDataReady] = useState(false);

  const { selectedExperiment } = useSelectedExperiment();
  const { selectedTrial } = useSelectedTrial();

  const [FullExperimentTemperatureDailyAverage, setFullExperimentTemperatureDailyAverage] = useState([]);
  const [FullExperimentHumidityDailyAverage, setFullExperimentHumidityDailyAverage] = useState([]);
  const [FullExperimentCO2DailyAverage, setFullExperimentCO2DailyAverage] = useState([]);

  const [SingleTrialEnvironmentAllData, setSingleTrialEnvironmentAllData] = useState([]);
  const [SingleTrialEnvironmentDailyAverage, setSingleTrialEnvironmentDailyAverage] = useState([]);
  const [SingleTrialActuatorAllData, setSingleTrialActuatorAllData] = useState([]);

  const handleAnalysisModeChange = (event) => {
    setAnalysisMode(event.target.value);
  };

  const handleGraphSelectionChange = (event) => {
    setGraphSelection(event.target.value);
  };

  const handleDataSliceChange = (event) => {
    setDataSlice(event.target.value);
  };

  const renderChartComponent = () => {
    if (!selectedExperiment && analysisMode === 'FullExperiment') return <CircularProgress />;
    if (!selectedTrial && analysisMode === 'SingleTrial') return <CircularProgress />;

    if (analysisMode === 'FullExperiment') {
      if (dataSlice === 'DailyAverage') {
        switch (graphSelection) {
          case 'temperature':
            return <TemperatureExperimentEnvironmentalObservationChart data={FullExperimentTemperatureDailyAverage} />;
          case 'humidity':
            return <HumidityExperimentEnvironmentalObservationChart data={FullExperimentHumidityDailyAverage} />;
          case 'co2':
            return <Co2ExperimentEnvironmentalObservationChart data={FullExperimentCO2DailyAverage} />;
          default:
            return <div>No data available for this selection. Please select a different chart.</div>;
        }
      } else if (dataSlice === 'AllData') {
        switch (graphSelection) {
          case 'temperature':
          case 'humidity':
          case 'co2':
            return <div>No chart available for this selection. Please select "Daily Average" to compare experiment data between trials.</div>;
          case 'IrrigationPump':
            return <div>No chart available for this selection. Please select "Single Trial" to view irrigation data.</div>;
        }
      }
    } else if (analysisMode === 'SingleTrial') {
      switch (dataSlice) {
        case 'DailyAverage':
          if (graphSelection === 'IrrigationPump') {
            return <div>No chart available for this selection. Please select "All Data" to view irrigation data.</div>;
          } else {
            return <DailyObservations data={SingleTrialEnvironmentDailyAverage} attribute={graphSelection} />;
          }
        case 'AllData':
          if (graphSelection === 'IrrigationPump') {
            return <PumpActuator data={SingleTrialActuatorAllData} attribute={'pump'} />;
          } else {
            return <AllObservations data={SingleTrialEnvironmentAllData} attribute={graphSelection} />;
          }
        default:
          return <div>No data available for this selection. Please select a different chart.</div>;
      }
    }
  };

  const renderChartComponentNotReady = () => {
    return !dataReady;
  };

  useEffect(() => {
    const fetchData = async () => {
      setDataReady(false);

      try {
        if (analysisMode === 'FullExperiment') {
          if (graphSelection === 'temperature' && dataSlice === 'DailyAverage') {
            const trialResponse = await axios.get(`/trials/exp/${selectedExperiment._id}`);
            const trials = trialResponse.data;

            const environmentalData = await Promise.all(
              trials.map(trial =>
                axios.get(`environment/${trial._id}`, {
                  params: { attribute: 'temperature', timeFrame: 'Daily' },
                }).then(response => response.data)
              )
            );

            const mergedData = [].concat.apply([], environmentalData);
            const groupedData = groupBy(mergedData, 'trial_id');
            const zeroIndexedData = Object.keys(groupedData).map(key => groupedData[key]);

            setFullExperimentTemperatureDailyAverage(zeroIndexedData);
          } else if (graphSelection === 'humidity' && dataSlice === 'DailyAverage') {
            const trialResponse = await axios.get(`/trials/exp/${selectedExperiment._id}`);
            const trials = trialResponse.data;

            const environmentalData = await Promise.all(
              trials.map(trial =>
                axios.get(`environment/${trial._id}`, {
                  params: { attribute: 'humidity', timeFrame: 'Daily' },
                }).then(response => response.data)
              )
            );

            const mergedData = [].concat.apply([], environmentalData);
            const groupedData = groupBy(mergedData, 'trial_id');
            const zeroIndexedData = Object.keys(groupedData).map(key => groupedData[key]);

            setFullExperimentHumidityDailyAverage(zeroIndexedData);
          } else if (graphSelection === 'co2' && dataSlice === 'DailyAverage') {
            const trialResponse = await axios.get(`/trials/exp/${selectedExperiment._id}`);
            const trials = trialResponse.data;

            const environmentalData = await Promise.all(
              trials.map(trial =>
                axios.get(`environment/${trial._id}`, {
                  params: { attribute: 'co2', timeFrame: 'Daily' },
                }).then(response => response.data)
              )
            );

            const mergedData = [].concat.apply([], environmentalData);
            const groupedData = groupBy(mergedData, 'trial_id');
            const zeroIndexedData = Object.keys(groupedData).map(key => groupedData[key]);

            setFullExperimentCO2DailyAverage(zeroIndexedData);
          }
        } else if (analysisMode === 'SingleTrial') {
          if (dataSlice === 'AllData' && graphSelection !== 'IrrigationPump') {
            const req = await axios.get(`/environment/${selectedTrial._id}`, {
              params: { attribute: graphSelection, timeFrame: 'Raw' },
            });
            setSingleTrialEnvironmentAllData(req.data);
          } else if (dataSlice === 'DailyAverage' && graphSelection !== 'IrrigationPump') {
            const req = await axios.get(`/environment/${selectedTrial._id}`, {
              params: { attribute: graphSelection, timeFrame: 'Daily' },
            });
            setSingleTrialEnvironmentDailyAverage(req.data);
          } else if (dataSlice === 'AllData' && graphSelection === 'IrrigationPump') {
            const req = await axios.get(`/pump/${selectedTrial._id}`, {
              params: { attribute: 'pump', timeFrame: 'Raw' },
            });
            setSingleTrialActuatorAllData(req.data);
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setDataReady(true);
      }
    };

    fetchData();
  }, [analysisMode, dataSlice, graphSelection, selectedExperiment, selectedTrial]);

  return (
    <ThemeProvider theme={greenTheme}>
      <Grid container direction="column-reverse" style={{ minHeight: '10vh' }}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" bgcolor="background.paper" p={1}>
            <Box p={2} border={1} borderRadius={16} borderColor="grey.500" mx={2}>
              <Typography className={classes.responsiveHeading}>Analysis Mode</Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="analysisMode" name="analysisMode" value={analysisMode} onChange={handleAnalysisModeChange}>
                  <FormControlLabel value="SingleTrial" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="Single Trial" />
                  <FormControlLabel value="FullExperiment" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="Full Experiment" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box display="flex" p={2} border={1} borderRadius={16} borderColor="grey.500" mx={2}>
              <Box pr={2}>
                <Typography className={classes.responsiveHeading}>Graph by Sensor</Typography>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="graphSelection" name="graphSelection" value={graphSelection} onChange={handleGraphSelectionChange}>
                    <FormControlLabel value="temperature" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="Temperature" />
                    <FormControlLabel value="humidity" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="Humidity" />
                    <FormControlLabel value="co2" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="CO2" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box pl={2}>
                <Typography className={classes.responsiveHeading}>Actuator Log</Typography>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="graphSelection" name="graphSelection" value={graphSelection} onChange={handleGraphSelectionChange}>
                    <FormControlLabel value="IrrigationPump" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="Irrigation Pump" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Box p={2} border={1} borderRadius={16} borderColor="grey.500" mx={2}>
              <Typography className={classes.responsiveHeading}>Data Slicing</Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="dataSlice" name="dataSlice" value={dataSlice} onChange={handleDataSliceChange}>
                  <FormControlLabel value="DailyAverage" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="Daily Average" />
                  <FormControlLabel value="AllData" className={classes.responsiveRadio} control={<Radio size='small' color="primary" />} label="All Data" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" bgcolor="background.paper" p={1} minHeight="65vh">
            {renderChartComponentNotReady() ? (
              <CircularProgress />
            ) : (
              <Paper elevation={3} style={{ padding: '10px', width: '50vw', maxHeight: '60vh' }}>
                {renderChartComponent()}
              </Paper>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Analysis;
